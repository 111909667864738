

.symlisticonbox {
    max-width: 30%;
    display: inline-block;
}

.symlisticonbox img {
    width: 100%;
    padding: 10%;
    vertical-align: middle;
    box-sizing: border-box;
}

.symlistnamebox {
    margin: auto 0;
}