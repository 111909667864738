:root {
    --keySize: 50px;
    --keyMargin: 0.2%;
    --refsize: 1000;
    --uibuttoncolor: rgb(251, 255, 21);
    --emptybuttoncolor: rgb(194, 194, 194);
    --activekeysymbolboxcolor: rgb(86, 255, 86);
}

#ref{
    width: calc(100% / var(--refsize) * 1000);
}

#keyboard{
    width: 1000px;
    background-color: rgb(233, 111, 90);
    visibility: hidden;
    transform-origin: top left;
    transform: scale(1);
}

#keyboard.active{
    background-color: rgb(134, 255, 134);
}

#hiddenkeyscontainer{
    visibility: hidden;
    height: 0;
    width: 0;
    display: none;
}

#keyscontainer{
    display: flex;
    flex-wrap: wrap;
    /*align-content: stretch;*/
    justify-content: center;
}

#keyboard .key{
    min-width: var(--keySize);
    min-height: var(--keySize);
    background-color: green;
    margin: var(--keyMargin);
    font-family: 'Segoe UI', 'Arial', 'sans-serif';
}

#keyboard .key.back{
    min-width: calc(1.5*var(--keySize));
}

#keyboard .key.tab{
    min-width: calc(1.33*var(--keySize));
}

#keyboard .key.buttonToMove.r2{
    min-width: calc(1.17*var(--keySize));
}

#keyboard .key.upperEnter{
    min-width: calc(1.17*var(--keySize));
}

#keyboard .key.caps{
    min-width: calc(1.67*var(--keySize));
    background-color: var(--emptybuttoncolor);
}

#keyboard .key.lowerEnter{
    min-width: calc(0.83*var(--keySize));
    margin-top: calc(-1*var(--keyMargin));
}

#keyboard .key.enter{
    min-width: calc(1.83*var(--keySize) + 2*var(--keyMargin));
}

#keyboard .key.shiftright{
    min-width: calc(2.5*var(--keySize) + 2*var(--keyMargin));
}

#keyboard .key.r5{
    min-height: calc(0.5*var(--keySize));
}

#keyboard .spacebutton{
    min-width: calc(3*var(--keySize));
}

#keyboard .keycontent{
    width: 100%;
    height: 100%;
    background-color: orange;
    display: grid;
    grid-template-columns: 27.5% 45% 27.5%;
    grid-template-rows: 27.5% 45% 27.5%;
}

#keyboard .uibutton .keycontent{
    background-color: var(--uibuttoncolor);
    cursor: pointer;
}

#keyboard .uibutton .keycontent:hover{
    background-color: rgb(254, 255, 205)
}

#keyboard .keysymbolbox{
    width: 100%;
    height: 100%;
    background-color: rgb(87, 165, 255);
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#keyboard .keysymbolbox.active{
    background-color: var(--activekeysymbolboxcolor);
}

#keyboard .keysymbolbox:hover{
    background-color: rgb(188, 219, 255);
}

#keyboard .keysymbolbox img{
    max-width: calc(0.25*var(--keySize));
    max-height: calc(0.25*var(--keySize));
    padding: 0;
    margin: 0;
}

#keyboard .keysymbolbox.k1 img{
    width: calc(0.42*var(--keySize));
}

#keyboard .uibutton .keysymbolbox{
    background-color: transparent;
}

#keyboard .uibutton .keysymbolbox:hover{
    background-color: transparent;
}

#keyboard .k1{
    grid-column-start: 2;
    grid-row-start: 2;
}

#keyboard .k2{
    grid-column-start: 1;
    grid-row-start: 1;
    box-sizing: border-box;
    font-size: 12px;
}

#keyboard .k3{
    grid-column-start: 3;
    grid-row-start: 1;
}

#keyboard .k4{
    grid-column-start: 1;
    grid-row-start: 3;
}

#keyboard .k5{
    grid-column-start: 3;
    grid-row-start: 3;
}

#keyboard .uibutton .k1 {
    font-size: 17px;
    box-sizing: border-box;
}

#keyboard .uibutton.r5 .k1 {
    font-size: 13px;
    padding-top: 0px;
    box-sizing: border-box;
}

#keyboard .uibutton .k1 .material-symbols-outlined {
    font-size: 17px;
}

#keyboard .uibutton.r5 .k1 .material-symbols-outlined {
    padding-top: 2px;
}

.keyboard-endrow{
    flex-basis: 100%;
    height: 0;
}

svg {
    width: 100%;
}